import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from '@emotion/styled'
import mq from '../styles/mediaQueries'
import { Link } from 'gatsby'

import HeroPageTemplate from '../templates/hero-page'
import Seo from '../components/seo'
import Gallery from '../components/Gallery'
import JobsTerminal from '../components/JobsTerminal'

import {
  Subheading1,
  Headline2,
  Headline4,
  Body2,
} from '../components/Typography'

import Layout from '../components/Layout'

const BackgroundWrapper = styled.div`
  background: #e5e5e5;
`

const GalleryPlaceholder = styled.div`
  background: #e5e5e5;
  height: 480px;
  width: 100vw;
`

const SubHeader = styled.div`
  ${Subheading1}
  color: var(--terminal-400);
  ${mq({
    gridColumnStart: ['1', '1', '1', '3', '3'],
    gridColumnEnd: ['5', '9', '13', '11', '11'],
    margin: ['92px 0 120px 0'],
  })};

  a {
    color: var(--screen-500);
    text-decoration: none;
  }
`

const TerminalWrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '2', '2', '2'],
    gridColumnEnd: ['5', '9', '12', '12', '12'],
  })};
`

const Title = styled.h2`
  ${Headline2};
  color: var(--terminal-500);
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: center;
  ${mq({
    margin: [
      '160px 0 80px 0',
      '160px 0 80px 0',
      '240px 0 120px 0',
      '240px 0 120px 0',
      '240px 0 120px 0',
    ],
  })};
`

const TextColumnWrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '1', '3', '3'],
    gridColumnEnd: ['5', '9', '13', '11', '11'],
    columnCount: ['1', '3', '3', '3', '3'],
    marginBottom: ['160px', '160px', '200px', '240px', '240px'],
  })};
  column-rule-style: solid;
  column-rule-width: 1px;
  column-rule-color: var(--terminal-100);
  column-gap: 72px;
`

const ColumnTitle = styled.div`
  ${Headline4};
  color: var(--terminal-500);
  margin-bottom: 16px;
`

const ColumnBody = styled.div`
  ${Body2};
  color: var(--terminal-400);
  break-inside: avoid-column;
  column-span: 1;
`

const ColumnHorizontalLine = styled.hr`
  border: 1px solid var(--terminal-100);
  margin: 24px 0;
  ${mq({
    display: ['block', 'none', 'none', 'none', 'none'],
  })};
`

const TextRowWrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '1', '3', '3'],
    gridColumnEnd: ['5', '9', '13', '11', '11'],
    columnCount: ['1'],
  })};
`

const HorizontalLine = styled.hr`
  border: 1px solid var(--terminal-100);
  margin: 24px 0;
`

function Careers() {
  const [windowResizing, setWindowResizing] = useState(false)
  const pageTitle = 'Careers'
  const [terminalRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  })

  useEffect(() => {
    let timeout
    const cachedWidth = window.innerWidth

    const handleResize = () => {
      clearTimeout(timeout)

      if (cachedWidth !== window.innerWidth) {
        setWindowResizing(true)
      }

      timeout = setTimeout(() => {
        setWindowResizing(false)
      }, 200)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const firstLine = 'We build exceptional software.'

  // if (we're hiring) {
  // const secondLine = 'We build exceptional software. We’re hiring.'
  // } else {
  const secondLine = 'And even better developers.'
  // }

  return (
    <BackgroundWrapper>
      <HeroPageTemplate
        theme={'day'}
        firstLine={firstLine}
        secondLine={secondLine}
        title={pageTitle}
        copyrightYear={'2022'}
      >
        <Seo
          title={pageTitle}
          description="Interested in a career at Super Good? We're always looking for highly skilled individuals."
          keywords={[
            `Ruby on Rails`,
            `Rails`,
            `React`,
            `Spree`,
            `Solidus`,
            `eCommerce`,
          ]}
        />
        <Layout>
          <SubHeader>
            We’re a globally-minded team and we take our values seriously. We’re
            fully-remote and currently span just 2 timezones while{' '}
            <Link to="/work">our clients</Link> span 12.
          </SubHeader>
          <TerminalWrapper ref={terminalRef}>
            {inView ? <JobsTerminal /> : null}
          </TerminalWrapper>
        </Layout>
        <Title>What's it like here</Title>
        <Layout>
          <TextColumnWrapper>
            <ColumnBody>
              <ColumnTitle>Remote</ColumnTitle>Our communication systems are set
              up to support a close-knit but fully remote team. Hours can be
              flexible and your office can be anywhere.
            </ColumnBody>
            <ColumnHorizontalLine />
            <ColumnBody>
              <ColumnTitle>Educational</ColumnTitle>We foster a culture of
              growth where everyone has access to the resources they need to
              develop their skills. Expect internal education, knowledge
              sharing, workshops, conferences etc.
            </ColumnBody>
            <ColumnHorizontalLine />
            <ColumnBody>
              <ColumnTitle>Culture-first</ColumnTitle>We welcome all people into
              our development team. Discrimination has no place here. Leading
              with inclusivity and openness is the Super Good way.
            </ColumnBody>
          </TextColumnWrapper>
        </Layout>
        {windowResizing ? <GalleryPlaceholder /> : <Gallery />}
        <Title>How we do things</Title>
        <Layout>
          <TextRowWrapper>
            <ColumnBody>
              <ColumnTitle>Client work</ColumnTitle>We work with clients whose
              values align with ours so we always feel great about doing our
              best work with them. Being on the same page from day one also
              means we’ll likely have long, meaningful relationships, full of
              growth opportunities and rewarding achievements.
            </ColumnBody>
            <HorizontalLine />
            <ColumnBody>
              <ColumnTitle>Team integration</ColumnTitle>We integrate with our
              clients, becoming more like partners with a collective hive mind
              than an outsourced solution. As an agile team, we work
              iteratively, with value and outcomes sitting at the centre of our
              process. Don't expect to find any fixed-bid projects or tight
              deadlines here.
            </ColumnBody>
            <HorizontalLine />
            <ColumnBody>
              <ColumnTitle>Technologies we use</ColumnTitle>Application
              development is our bread and butter. We build open-source
              solutions primarily using Solidus. As leaders in the Solidus
              space, we share information openly and continuously push
              boundaries to be the best team of Solidus leaders and status quo
              challengers.
            </ColumnBody>
          </TextRowWrapper>
        </Layout>
      </HeroPageTemplate>
    </BackgroundWrapper>
  )
}

export default Careers
